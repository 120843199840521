/* Toastify
--------------------------------------------------------------------------------------------------------*/
.Toastify__toast {
  z-index: 9999;
  border-left: 4px solid #222629;
  border-radius: 4px;
  padding: 15px 45px;
  font-family: 'Lato', sans-serif;
  background-color: #3d3d3e !important;
  position: relative;
}
.Toastify__toast .Toastify__close-button {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
}
.Toastify__toast .Toastify__toast-body {
  font-size: 14px;
  color: #fff;
}

.Toastify__toast::after {
  content: '';
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 19px;
  left: 10px;
  background-size: 24px 24px;
}
.Toastify__toast--success {
  border-left-color: #2bde3f;
}
/* .Toastify__toast--success::after {background-image:url(../images/success-icon.svg);} */
.Toastify__toast--warning {
  border-left-color: #ffc007;
}
/* .Toastify__toast--warning::after {background-image:url(../images/warning-icon.svg);} */
.Toastify__toast--error {
  border-left-color: #fb5151;
}
/* .Toastify__toast--error::after {background-image:url(../images/error-icon.svg);} */
.Toastify__toast--info {
  border-left-color: #1d72f3;
}
/* .Toastify__toast--info::after {background-image:url(../images/info-icon.svg);} */

.container {
  background-color: #141b29;
}

.site-layout-background {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 24px;
}

.d-inline-flex {
  display: flex;
}

/* Loader 
----------------------------------------------------------------------------------------------------------*/
.centered {
  /*
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.logo {
  margin-bottom: 20px;
  height: 134px;
  width: 155px;
  align-items: center;
}
.logo > img {
  display: block;
}

.logo-img {
  height: 114px;
  width: auto;
  align-items: center;
}

/* Sign in page 
--------------------------------------------------------------------------------------------------------*/
.main-contentlogin {
  padding: 125px 0px 0;
}
.login-outer {
  display: table;
  width: 100%;
  height: 100vh;
  background-color: #141b29;
}
.login-inner {
  display: table-cell;
  vertical-align: middle;
}
.login-form {
  /* padding: 0px 15px; */
  max-width: 246px;
  width: 100%;
  margin: auto;
}
.login-form .logo {
  /* padding: 0 0 30px 0; */
  display: inline-block;
  margin-left: 47px;
}
.login-form .loing-name {
  color: #e8e8e8;
  font-size: 18px;
  line-height: normal;
  padding-bottom: 20px;
}
.login-form .form-group {
  margin-bottom: 20px;
}
.login-form .form-group .form-control {
  font-size: 16px;
  padding: 0 10px;
  border-radius: 6px;
  height: 48px;
  width: 246px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.login-form .form-group .custom-control-label {
  color: #fff;
}
.login-form .forgot-password {
  font-size: 16px;
  color: #fff;
  text-align: left;
}
.login-form .forgot-password:hover {
  color: #0077d2;
}
.login-form p {
  font-size: 16px;
  color: #fff;
}
.login-form .sign-up-now {
  height: 40px;
  border-radius: 40px !important;
}
.login-form .btn-login {
  display: inline-flex;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0bc7a0 !important;
  text-align: center;
  border: none;
}
.login-form .btn-login:hover {
  background-color: #0bc7a0 !important;
}
.login-form .btn-login img {
  width: 8px;
  height: 16px;
  display: block;
  margin: auto;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #f85050 !important;
  font-size: 14px;
  line-height: 24px;
}
.ant-input-suffix {
  display: none !important;
}

label {
  font-size: 14px;
  line-height: 18px;
}
.label {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
}
.form-label-hide .ant-form-item-label > label {
  display: none;
}
.signin-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  grid-gap: 8px;
  gap: 8px;
}
.border-dashed {
  border-bottom: dashed 1px #ffffff;
  height: 1px;
  margin: 20px 0;
}
.btn-create {
  display: inline-flex;
  width: 246px;
  height: 60px;
  border-radius: 30px;
  border: solid 1px #ffffff;
  color: #ffffff;
  padding-left: 15px;
}
.btn-create img {
  width: 8px;
  height: 16px;
  display: block;
  margin: auto;
}
.btn-create span {
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  padding: 16px 15px;
}

.signin-loading svg {
  path {
    fill: #fff;
    stroke: #fff;
  }
}

/* Sidebar
--------------------------------------------------------------------------------------------------------------- */

.sidebar {
  min-height: 100vh;
  width: 220px !important;
  background-color: #141b29 !important;
  text-align: center;
}

.sidebar-logo {
  padding: 20px;
  transition: all 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-logo:hover {
  transform: scale(1.05);
}

.sidebar-logo .sidebar-logo-img {
  height: 75px;
  width: 158px;
  object-fit: contain;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1a2133 !important;
  font-weight: 700;
}

.menu-item {
  height: 69px !important;
  border-bottom: 1px solid #1a2133;
  margin: 0px !important;
}

.ant-menu-dark .ant-menu-item > span > a {
  color: #ffffff !important;
}

/* Titlebar
----------------------------------------------------------------------------------------------------------- */
.width-900-container {
  max-width: 800px !important;
  margin: 0 auto;
}

.ant-layout-header {
  line-height: inherit !important;
}
/* 
.ant-layout-header.header {
  padding: 0;
  background-color: #f7f7f7;
  height: 100px;
  z-index: 10;
}

.header-content {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.ant-layout-header.header {
  padding: 0;
  background-color: #f7f7f7;
  height: 100px;
  z-index: 10;
}

.header-content {
  /* padding-top: 16px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  height: inherit;
}

.title {
  color: #292929;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
}

.location-dropdown {
  font-size: 12px;
  width: 182px;
  height: 39px;
  border: 1px solid #e7e7e9;
  border-radius: 3px;
  color: #292929;
  font-weight: 400;
}
.prefix-location {
  z-index: 10;
  position: relative;
  left: 17px;
}
.global-dropdown.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* padding-left: 20px !important; */
  /* padding-right: 11px !important; */
  width: 100%;
  height: 100%;
  padding: 2px 11px 2px 20px;
}

/* Tabs
------------------------------------------------------------------------------------------- */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-ink-bar {
  background: transparent;
}

/*  Table
------------------------------------------------------------------------------------------ */

.custom-table .ant-table-thead > tr > th {
  color: #292929;
  background: transparent;
  font-weight: 700;
  border-bottom: 1px solid #f1f1f2;
}

.ic-right-arrow {
  transform: rotate(-90deg);
}

.fuel-green {
  height: 34px;
  width: 34px;
  border: 1px solid #d1f7c2;
  border-radius: 3px;
  text-align: center;
  color: #3ca114;
  padding: 5px;
}

.fuel-red {
  height: 34px;
  width: 34px;
  border: 1px solid #ebcece;
  border-radius: 3px;
  text-align: center;
  color: #a11414;
  padding: 5px;
}

.sentiment-troubled {
  width: 111px;
  height: 34px;
  background-color: rgb(252 182 26 / 0.11);
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  color: #cb9e1b;
}

.sentiment-suspicious {
  width: 111px;
  height: 34px;
  background-color: rgb(244 38 167 / 0.11);
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  color: #b628cb;
}

.sentiment-interested {
  width: 111px;
  height: 34px;
  background-color: rgb(38 187 244 / 0.11);
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  color: #28a8cb;
}

.sentiment-alarmed {
  width: 111px;
  height: 34px;
  background-color: rgb(200 34 39 / 0.11);
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  color: #c82227;
}

.status-0 {
  color: #5d6c75;
}

.status-1 {
  color: #86c65a;
}

.status-2 {
  color: #ffa701;
}

.status-3,
.rental-status-readyline {
  color: #3ca114;
}

.status-4 {
  color: red;
}

.status-5 {
  color: #53b9d5;
}

.status-dealer-0 {
  color: #5d6c75;
}

.status-dealer-1 {
  color: #2970ff;
}

.status-dealer-2 {
  color: #ffa701;
}

.status-dealer-3 {
  color: #1ad690;
}

.status-dealer-4 {
  color: #ea5959;
}

.status-dealer-5 {
  color: #ea5959;
}

.ant-tabs-tab {
  border: 1px solid #f1f1f2 !important;
  height: 31px !important;
  border-radius: 15.5px !important;
  min-width: 53px;
  padding: 12px 18px !important;
  margin-bottom: 5px;
  font-size: 12px !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #141b29;
  color: #ffffff !important;
}

.ant-switch-checked {
  background-color: #3ca116 !important;
}

.input-filter-group {
  display: flex;
}
.input-filter-group .ant-select .ant-select-selector,
.input-filter-group .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-filter-group > .filter-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab {
  list-style: none;
  display: flex;
  overflow: hidden;
}

/* .ant-form-item-control-input {
  border: 1px solid #bfbfbf !important;
  border-radius: 3px !important;
} */

.ant-select-selection-placeholder {
  font-weight: 400 !important;
}

.sign-out {
  text-align: center;
  position: absolute;
  left: 70px;
  bottom: 20px;
}

.sign-out a {
  color: #ffffff;
}

/* Damage details page
----------------------------------------------------------------------------------------------------- */

.ant-card-head-title {
  padding: 7px 0 !important;
}
.ant-card-extra {
  padding: 7px 0 !important;
}

.breadcrumbs {
  font-size: 14px;
}

.left-header {
  /* height: '33px'; */
  display: grid;
}

.right-header {
  /* height: '33px'; */
  display: flex;
}

.btn-submit {
  /* width: 146px; */
  height: 42px;
  border-radius: 21px;
  background-color: #22c782;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  display: block;
  margin: auto;
  padding: 10px 23px;
  cursor: pointer;
}

.btn-edit {
  height: 42px;
  border-radius: 21px;
  background-color: #fcb00f;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  display: block;
  margin: auto;
  padding: 10px 23px;
  cursor: pointer;
}

.btn-modal-submit {
  height: 42px;
  margin: auto;
  padding: 10px 23px;
  border-color: transparent;
  border-radius: 21px;
  background-color: #22c782;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
}

.btn-modal-cancel {
  height: 42px;
  margin: auto;
  padding: 10px 23px;
  border-color: #141b29;
  border-radius: 21px;
  background-color: transparent;
  text-align: center;
  color: #141b29;
  font-weight: 700;
  cursor: pointer;
}

.btn-select-parts {
  height: 42px;
  margin: auto;
  padding: 10px 23px;
  border-color: #141b29;
  border-radius: 21px;
  background-color: transparent;
  text-align: center;
  color: #141b29;
  font-weight: 700;
  cursor: pointer;
}

.div-select-parts {
  text-align: right;
  margin: 4px;
  margin-right: 160px;
}

.notes-input {
  height: 45px;
  border-radius: 5px !important;
  border: 1px solid rgb(184 184 184/ 0.5) !important;
}

.header-title {
  /* margin: 20px 0px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(246 246 246 / 0.5);
  height: 68px;
  /* padding: 0px 50px; */
}

.text-bold {
  font-weight: 700;
}

/* .image-content {
  padding: 9px 24px;
} */

/* .image-card {
  height: 188px;
  width: 188px;
  border-radius: 5px !important;
  border: 1px solid #e3e3e3 !important;
  margin: 9px 8px !important;
  cursor: pointer;
} */

.image-card {
  height: 188px;
  width: 188px;
  border-radius: 5px !important;
  border: 1px solid #e3e3e3 !important;
  margin: 9px 0 !important;
  cursor: pointer;
}

.ant-card-body {
  padding: 0 !important;
}

.ant-card-head {
  min-height: 32px !important;
  max-height: 48px;
  padding: 0 10px !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700 !important;
  font-size: 14px !important;
  background: transparent;
}

.image-card-annotation {
  height: 188px;
  width: 188px;
  border-radius: 5px !important;
  margin: 9px 0px !important;
  cursor: pointer;
}

.image-card-annotation .ant-card-head {
  padding: 0 10px !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.image-card-annotation .ant-card-head span {
  color: #ffffff;
  font-weight: 700;
}

.image-card-newdamage {
  border: 2px solid #c72222 !important;
}

.image-card-newdamage .ant-card-head {
  background: #c72222 !important;
}

.image-card-unsure {
  border: 2px solid #848484 !important;
}

.image-card-unsure .ant-card-head {
  background: #848484 !important;
}

.image-card-preexisting {
  border: 2px solid #e39542 !important;
}

.image-card-preexisting .ant-card-head {
  background: #e39542 !important;
}

.image-card-fairwear {
  border: 2px solid #0bc7a0 !important;
}

.image-card-fairwear .ant-card-head {
  background: #0bc7a0 !important;
}

.image-card-ignore {
  border: 2px solid #2d6281 !important;
}

.image-card-ignore .ant-card-head {
  background: #2d6281 !important;
}

.damage-cropped-image {
  width: 100%;
  height: 149.5px;
  position: absolute;
  top: 36px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.car-cropped-image {
  height: 108px;
  position: absolute;
}

.text-troubled {
  color: #cb9e1b;
}
.text-suspicious {
  color: #b628cb;
}
.text-alarmed {
  color: #c82227;
}
.text-interested {
  color: #28a8cb;
}
.image-height {
  max-width: 0% !important;
}

/* Damage details closeup review
------------------------------------------------------------------------------------------------------ */

.image-index {
  background-color: #c72222;
  border-radius: 4px;
  height: 38px;
  width: 38px;
  text-align: center;
  padding: 4px;
  color: #ffffff;
  font-weight: 700;
  font-size: 19px;
  margin-left: 10px;
}

.btn-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  width: 308px;
}
.btn-navigation span {
  text-decoration: underline;
  color: #141b29;
  /* cursor: pointer; */
}

.btn-navigation a {
  color: #ffffff;
  text-decoration: underline !important;
}

.btn-navigation a:hover {
  color: #ffffff;
}

.latest-image-card {
  height: 452px;
  background-color: #101623;
  padding: 30px 210px;
}

.image-block {
  display: 'inline-flex';
  margin-top: '28px';
}

.btn-block {
  display: 'inline-block';
  margin-left: '21px';
}

.zoomed-image {
  height: 308px;
  width: 308px;
  border: 2px solid #ffcd11;
  margin-left: 15px;
}

.closeup-review-image {
  height: 308px;
  width: 308px;
}

.closeup-review-image .ReactCrop__crop-selection {
  border: 3px solid #ffcd11;
}

.annotation-newdamage {
  width: 132px;
  height: 37px;
  background-color: #c72222;
  text-align: center;
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
}

.annotation-unsure {
  width: 132px;
  height: 37px;
  background-color: #848484;
  text-align: center;
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
}

.icon-scan {
  margin-top: 7px;
  margin-left: 18px;
  height: 7px;
  width: 12px;
}

.rental-status-checkedin {
  color: #ff8200;
}

.rental-status-checkout {
  color: #9514a1;
}

.btn-annotation {
  display: inline-flex !important;
  /* width: 146px; */
  height: 36px !important;
  border-radius: 5px 23.5px 23.5px 5px !important;
  padding: 8px 11px !important;
}
.btn-annotation span {
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
}
.btn-annotation img {
  width: 14px;
  height: 14px;
  display: block;
  margin: auto;
  margin-right: 0;
  margin-left: 11px;
}

.btn-selected-new-damage,
.btn-selected-new-damage:hover,
.btn-selected-new-damage:focus {
  background-color: #c72222;
  border: 1px solid #c72222;
}
.btn-new-damage,
.btn-new-damage:hover,
.btn-new-damage:focus {
  background-color: rgb(199, 34, 34, 0.29);
  border: 1px solid rgb(199, 34, 34, 0.47) !important;
}
.btn-selected-fairwear,
.btn-selected-fairwear:hover,
.btn-selected-fairwear:focus {
  background-color: #0bc7a0;
  border: 1px solid #0bc7a0;
}
.btn-fairwear,
.btn-fairwear:hover,
.btn-fairwear:focus {
  background-color: rgb(11, 199, 160, 0.13);
  border: 1px solid rgb(11, 199, 160, 0.48) !important;
}
.btn-selected-pre-existing,
.btn-selected-pre-existing:hover,
.btn-selected-pre-existing:focus {
  background-color: #e39542;
  border: 1px solid #e39542;
}
.btn-pre-existing,
.btn-pre-existing:hover,
.btn-pre-existing:focus {
  background-color: rgb(227, 149, 66, 0.13);
  border: 1px solid rgb(227, 149, 66, 0.46) !important;
}
.btn-selected-ignore,
.btn-selected-ignore:hover,
.btn-selected-ignore:focus {
  background-color: #2d6281;
  border: 1px solid #2d6281;
}
.btn-ignore,
.btn-ignore:hover,
.btn-ignore:focus {
  background-color: rgb(45, 98, 129, 0.13);
  border: 1px solid rgb(45, 98, 129, 0.48) !important;
}
.btn-selected-unsure,
.btn-selected-unsure:hover,
.btn-selected-unsure:focus {
  background-color: #848484;
  border: 1px solid #848484;
}
.btn-unsure,
.btn-unsure:hover,
.btn-unsure:focus {
  background-color: rgb(132, 132, 132, 0.13);
  border: 1px solid rgb(132, 132, 132, 0.45) !important;
}

.display-image {
  display: flex;
  max-width: 631px;
  margin-top: 28px;
  flex-grow: 1;
  flex-shrink: 0;
}

.btn-disabled,
.btn-disabled span {
  color: #b8b8b8 !important;
  cursor: not-allowed;
}

.loading {
  text-align: center;
  position: relative;
  top: 50%;
}

.overview-image-crop .ReactCrop__image {
  height: 0.5px;
  width: 0.5px;
  visibility: hidden;
}

.label-review {
  width: 147px;
  height: 33px;
  border-radius: 4px;
  background-color: rgb(16 22 35 / 0.08);
  text-align: center;
  color: #101623;
  font-weight: 700;
  display: block;
  margin: auto;
  padding: 5px;
}

.inline-header .ant-tabs-nav {
  background-color: rgb(241 242 242 / 0.5);
  height: 68px;
  padding: 16px 0;
  margin: 0 !important;
}

.inline-header.damage-details-tab .ant-tabs-tab {
  border: 1px solid rgb(151 151 151 / 0.53) !important;
  height: 35px !important;
  border-radius: 17.5px !important;
  padding: 0 18px !important;
  font-size: 14px !important;
}

.no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #354f80;
  font-size: 18px;
  padding: 0;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow: hidden;
}

.action-dropdown .ant-select-selector {
  width: 158px;
  height: 42px;
  border: 1px solid #dee7e7 !important;
  border-radius: 5px !important;
}

/* Comparison report
------------------------------------------------------------------------------------------ */
.comparison-report-workflow-0 {
  color: #2970ff;
}

.comparison-report-workflow-1 {
  color: #53b9d5;
}

.comparison-report-workflow-2 {
  color: #11c884;
}

/* asset details
------------------------------------------------------------------------------------------ */

.btn-asset-edit {
  border: 1px solid #f1f1f2;
  height: 31px;
  width: 63px;
  border-radius: 15.5px;
  text-align: center;
  padding: 3px 0;
  cursor: pointer;
}

.ant-btn.btn-asset {
  border: none;
  height: 31px;
  width: 63px;
  border-radius: 15.5px;
  text-align: center;
  padding: 3px 0;
}

.ant-btn.btn-asset-cancel,
.ant-btn.btn-asset-cancel:hover,
.ant-btn.btn-asset-cancel:focus {
  margin-right: 20px;
  background-color: #f6f9ff;
  color: #231f20;
}

.ant-btn.btn-asset-submit,
.ant-btn.btn-asset-submit:hover,
.ant-btn.btn-asset-submit:focus {
  background-color: #3ca114;
  color: #ffffff;
}

.w-100 {
  width: 100%;
}

.asset-condition {
  color: #d2d2d2;
}

.asset-condition-undamaged {
  color: #d2d2d2;
}

.asset-condition-damaged {
  text-align: left; /* dummy value to avoid empty style */
}

.ant-input-number {
  width: 100% !important;
}

.previous-link {
  line-height: 17px;
  color: #000000;
  text-decoration: underline;
}

.previous-link:hover {
  text-decoration: underline;
}

.ant-empty-normal.empty-image {
  margin: 18px 0;
}
/* changes in closeup reviews screen
----------------------------------------------------------------------------------------------- */

.image-index-new {
  border: 1px solid #c72222;
  border-radius: 4px;
  height: 37px;
  width: 37px;
  text-align: center;
  padding: 2px;
  color: #c72222;
  font-weight: 700;
  font-size: 19px;
  margin-right: 15px;
}

.sub-tab .ant-tabs-nav {
  background-color: #fcfcfc;
  height: 68px;
  padding: 17px 0;
  margin: 0 !important;
}

.card {
  width: 400px;
  /* height: 883px; */
  /* background-color: #eeeeee; */
  border: 1px solid rgb(151 151 151 / 0.17);
  border-radius: 5px;
  /* padding: 20px; */
  margin: 9px 0;
}

.card.gantry-card {
  width: 400px;
  /* height: 883px; */
  background-color: #eeeeee;
  border: 1px solid rgb(151 151 151 / 0.17);
  border-radius: 5px;
  padding: 20px;
  margin: 9px 0;
}
.gantry-card {
  max-height: 883px;
  overflow-y: scroll;
}

.zoomed-image-new {
  height: 365px;
  width: 365px;
  border: 2px solid #ffcd11;
}

.closeup-review-image-new {
  height: 365px;
  width: 365px;
}

.closeup-review-image-new .ReactCrop__crop-selection {
  border: 3px solid #ffcd11;
}

.card .card-header {
  justify-content: space-between;
  border-bottom: 1px dashed #dee7e7;
  height: 60px;
  padding: 20px;
}

.image-nav-new {
  margin: 13px 20px;
  height: 64px;
  align-items: center;
}

.image-navigation-new {
  max-width: 64px;
  max-height: 64px;
  width: auto;
  height: auto;
}
.current-image-new {
  border: 2px solid #ffcd11;
}

.icon-post-text {
  height: 16px;
  width: 16px;
  margin-left: 8px;
}

.annotation-title-bar-new {
  background-color: #141b29;
  height: 68px;
  align-items: center;
  /* justify-content: center; */
}

.annotation-title-bar-new .annotation-title-color-new {
  width: 30%;
  height: 68px;
  text-align: right;
  padding: 23px 23px 23px 0;
}

.sentiment-title-troubled {
  background-color: #cb9e1b;
}

.sentiment-title-suspicious {
  background-color: #b628cb;
}

.sentiment-title-interested {
  background-color: #28a8cb;
}

.sentiment-title-alarmed {
  background-color: #c82227;
}

.gantry-image {
  width: 361px;
  margin-top: 20px;
  /* border: 2px solid #ffcd11; */
}

.image-gallery .react-transform-wrapper {
  margin: auto;
}

.images-carousel .image-gallery-content .image-gallery-slide img {
  max-height: calc(100vh - 80px - 100px - 75px) !important;
  width: 100%;
  object-fit: contain;
}

.scan-review-carousel .image-gallery-content .image-gallery-slide img {
  max-height: calc(80vh) !important;
  width: 100%;
  object-fit: contain;
}

.gantry-card .ant-checkbox-wrapper,
.claims-images-content .ant-checkbox-wrapper {
  position: relative;
}
.gantry-card .ant-checkbox-wrapper .ant-checkbox {
  position: absolute;
  top: 31px;
  left: 13px;
}
.claims-images-content .ant-checkbox-wrapper .ant-checkbox {
  position: absolute;
  top: 11px;
  left: 12px;
}

.gantry-card .ant-checkbox-wrapper .ant-checkbox + span,
.claims-images-content .ant-checkbox-wrapper .ant-checkbox + span {
  padding-right: 0;
  padding-left: 0;
}

.gantry-card .ant-checkbox-wrapper + .ant-checkbox-wrapper,
.claims-images-content .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.claim-status-5 {
  color: #eca827;
}

.status-received,
.status-quote-viewed,
.claim-status-2,
.repairer-status-submitted {
  color: #2d99da;
}

.claim-status-1,
.status-quote-requested,
.repairer-status-received {
  color: #c630a1;
}

.claim-status-6,
.claim-status-3 {
  color: #4dae25;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  display: none;
}

.search-input .ant-input-affix-wrapper {
  border: 1px solid rgb(184 184 184 / 0.5);
  border-radius: 5px !important;
  height: 45px;
}

.search-input.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.search-box,
.close-icon,
.search-wrapper {
  position: relative;
  /* padding: 10px; */
}
.search-wrapper {
  width: 100%;
  margin: auto;
  margin-top: 50px;
}
.search-box {
  width: 95%;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 15px;
}
.search-box:focus {
  box-shadow: 0 0 15px 5px #b0e0ee;
  border: 2px solid #bebede;
}
.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.close-icon:after {
  content: 'X';
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  background-color: #fa9595;
  z-index: 1;
  right: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 12px;
  box-shadow: 0 0 2px #e50f0f;
  cursor: pointer;
}
.search-box:not(:valid) ~ .close-icon {
  display: none;
}

.claims-images {
  background-color: #10151f;
  height: 273px;
}

.claims-images-content {
  height: 100%;
  padding: 30px 0;
}

.claim-image {
  height: 168px;
  width: 168px;
  border-radius: 3px;
}

.btn-next {
  display: inline-flex;
  background-color: #5db137;
  border-radius: 21px;
  height: 42px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  margin: auto;
  padding: 10px 18px;
  cursor: pointer;
}
.btn-next img {
  width: 8px;
  height: 16px;
  display: block;
  margin: auto;
}
.btn-next span {
  padding-right: 15px;
}

.btn-disabled {
  background-color: #e5e5e5;
  color: #ababab;
}

.btn-disabled:hover {
  cursor: not-allowed;
}

.ant-btn.btn-load {
  /* width: 146px; */
  height: 42px;
  border-radius: 21px;
  border: 1px solid #979797;
  color: #5d5d5d;
  padding: 0 25px;
  float: right;
  margin: 15px 0;
}

.claims-tag {
  height: 30px;
  text-align: center;
  border-radius: 6px;
  padding: 6px 11px;
  font-weight: 700;
  margin-left: 10px;
}

.claims-tag.no-data {
  background-color: rgb(210 210 210 / 0.2);
  color: #b1b1b1;
}

.claims-tag.low {
  background-color: rgb(199 34 34 / 0.2);
  color: #c72222;
}

.claims-tag.medium {
  background-color: rgb(236 168 39 / 0.2);
  color: #eca827;
}

.claims-tag.high {
  background-color: rgb(93 177 55 / 0.2);
  color: #5db137;
}

.claims-images-content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5db137;
  border-color: #5db137;
}

.selected-claim {
  border: 2px solid #5db137;
}

.btn-reassess {
  border: 1px solid #141b29;
  height: 45px;
  border-radius: 22.5px;
  padding: 10px 26px;
  cursor: pointer;
  color: #141b29;
}

.details-title {
  margin-top: 35px;
  margin-bottom: 17px;
  font-size: 14px;
}

.overview-images-wrapper {
  margin-top: 12px;
  margin-bottom: 12px;
}

.overview-original-image-wrapper {
  width: 144px;
  float: left;
  height: 126px;
  background: white;
  margin: 8px;
}

.site-card-wrapper .ant-col.ant-col-6 {
  padding: 0 !important;
}

.site-card-wrapper .ant-card-head {
  font-size: 12px !important;
}

.empty-quote {
  background-color: rgb(241 241 242 / 0.43);
  border-radius: 5px;
  height: 68px;
  color: #292929;
  align-items: center;
  font-style: italic;
  padding: 0 27px;
  margin-bottom: 10px;
}

.selectbox {
  height: 39px;
  width: 91px;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.selectbox.selected {
  background-color: #5db137;
}
.selectbox.selected .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #ffffff;
}
.selectbox.selected .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #5db137;
  border-top: 0;
  border-left: 0;
}
.selectbox.selected .ant-checkbox-wrapper {
  color: #ffffff;
}
.selectbox .ant-checkbox-wrapper {
  font-size: 12px;
}

/* .status-quote-requested, .repairer-status-received {
  color: #c630a1;
} */
/* .status-quote-viewed {
  color: #2d99da;
} */
.status-quote-received,
.status-quote-selected,
.status-quote-submitted,
.repairer-status-accepted {
  color: #5db137;
}

.repairer-status-lost {
  color: #a8a8a8;
}

.text-white .ant-empty-description span {
  color: rgb(250 250 250 / 0.4);
}

.selected-quote {
  border: 1px solid rgb(218 218 218 / 0.43);
  border-radius: 5px;
  padding: 32px 27px;
}

.item-line {
  background-color: rgb(216 216 216 / 0.31);
  padding: 6px;
  margin-top: 8px;
}

.range-slider .ant-slider-rail {
  height: 16px;
  background-color: #d8d8d8;
}

.range-slider .ant-slider-track,
.range-slider .ant-slider-disabled .ant-slider-track {
  height: 16px;
  background-color: #5db137 !important;
}

.range-slider.ant-slider:hover .ant-slider-track {
  background-color: #5db137;
}
.range-slider .ant-slider-handle {
  height: 16px;
  margin-top: 0px;
  border: solid 2px #5db137;
  display: none;
}

.range-slider .ant-slider-dot {
  display: none;
}

.range-slider .ant-slider-mark-text {
  margin-top: 10px;
}

.ant-radio-inner::after {
  background-color: #5db137;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #5db137;
}

.ant-radio-wrapper {
  border: 1px solid #e3e3e3;
  padding: 19px 16px;
  border-radius: 3px;
}

.ant-radio-wrapper.ant-radio-wrapper-checked {
  border: 1px solid #5db137;
}

.ant-radio-group.ant-radio-group-outline::selection {
  background: #5db137;
}

.ant-radio-group.ant-radio-group-outline *::selection {
  background: #5db137;
}

.ant-radio-button-wrapper:hover {
  color: #5db137;
}

.ant-radio-button-wrapper-checked:hover {
  color: #5db137;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #5db137;
  border-color: #5db137;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #5db137;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #467e30;
  border-color: #467e30;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #467e30;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #8cd067;
  border-color: #8cd067;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #8cd067;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #8cd067;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #8cd067;
}

.override-range .ant-input {
  width: 100px;
  margin-top: 10px;
}

.btn-next.btn-create-quote {
  background-color: #141b29;
}

.repairer-notes {
  background-color: rgb(241 241 242 / 0.43);
  height: 202px;
  margin-bottom: 24px;
  padding: 23px 25px;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 8px;
}

.blur-row {
  opacity: 0.75;
}
.locations-table .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: unset;
}

.right-sidebar {
  background-color: rgb(241 241 242 / 0.45);
  filter: alpha(opacity=50);
  width: 420px;
  flex: 0 0 420px;
  max-width: 420px;
  min-width: 420px;
  padding: 0 32px;
}

.right-sidebar .ant-card {
  background: rgb(241 241 242 / 0.45);
}

.two-columns-column {
  flex: 50%;
  padding: 4px;
}

.two-columns-row {
  display: flex;
}

.divider.ant-divider-horizontal {
  margin: 0;
}

/* ------------------- */
.dynamic-delete-button {
  position: relative;
  top: 11px;
  margin-right: 10px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-add-line-item,
.btn-add-line-item:hover {
  color: #292929;
  text-decoration: underline;
  font-size: 12px;
}

.final-amount-input .ant-input {
  height: 46px;
}
.final-amount-input .ant-form-item-label > label {
  align-items: flex-end;
}

.quote-list-item .ant-input,
.quote-list-item .ant-input-number {
  background-color: #f1f1f2 !important;
  border: none !important;
  height: 46px;
}
.quote-list-item .ant-input-number {
  padding: 7px 0;
}
.quote-list-item .ant-form-item-control-input {
  border: none !important;
}
.quote-list-item .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f1f1f2 !important;
  border: none;
  height: 46px;
}
.quote-list-item .ant-select-single .ant-select-selector .ant-select-selection-item {
  padding: 6px 18px 6px 0;
}

.quote-list-item .ant-form-item .ant-select {
  width: 100%;
}

.quote-line-item .ant-input {
  text-transform: uppercase;
}

.filter-white {
  filter: invert(100%) sepia(95%) saturate(14%) hue-rotate(208deg) brightness(105%) contrast(100%);
}

.info-box {
  background-color: #ffdcd2;
  height: 78px;
  border-radius: 3px;
  margin: 24px 0;
  padding: 27px 33px;
  font-size: 16px;
}
/* ------------------------ */
/* Edit details
--------------------------------------------------------------------------------------------------------*/
input[readonly] {
  background-color: #f8f8f8;
}

/* ------------------------ */
/* Batch upload page
----------------------------------------------------------------------------------------------------- */
#batch-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#upload-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#upload-form input {
  float: right;
  margin: 20px 0px 20px 20px;
}

#drop-zone {
  border: 3px dashed rgb(149, 167, 198, 0.5);
  border-radius: 16px;
  display: inline-block;
  width: 100%;
}

#icon-upload {
  padding: 10px;
}

.highlight {
  border: 3px dashed rgb(149, 167, 198, 1) !important;
}

#file-submit {
  color: white;
  background-color: #4372c4;
  border-radius: 16px;
  border: none;
  padding: 15px;
}

::-webkit-file-upload-button {
  color: white;
  background-color: #4372c4;
  border-radius: 16px;
  border: none;
  padding: 15px;
  margin-right: 10px;
}

#error-msg {
  padding: 20px;
}

/* ------------------------ */
/* Workshop finder page
----------------------------------------------------------------------------------------------------- */
.map-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.workshop-finder-wrapper {
  position: relative;
  height: 100%;
}

#workshop-finder {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

/* ------------------------ */
/* Damage groups page
----------------------------------------------------------------------------------------------------- */
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  color: white;
  font-size: 30px;
  height: 30px;
  width: 30px;
  z-index: 1;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-prev:hover {
  left: 5px;
  z-index: 2;
  color: white;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:focus,
.ant-carousel .slick-next:hover {
  right: 5px;
  z-index: 2;
  color: white;
}

/* ------------------------ */
/* User and location form page
----------------------------------------------------------------------------------------------------- */
.location-form .form-input:disabled {
  color: rgba(0, 0, 0, 0.25);
}
.user-form .form-input:disabled {
  color: rgba(0, 0, 0, 0.25);
}

.admin-btn {
  padding: 10px;
  height: auto !important;
  border: none !important; /* Ensure no border by default */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
  transition: box-shadow 0.2s, border-color 0.2s; /* Proper syntax */
}

.admin-btn:hover {
  border: transparent !important;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.admin-logo {
  padding: 37px 0;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #1a2133;
}

.admin-logo .admin-logo-img {
  height: 75px;
  width: 158px;
  object-fit: contain;
}

/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
@media (max-width: 1439px) {
  .site-layout-background {
    margin: 24px;
  }
  .header-content {
    margin: 0 24px;
  }
  .width-900-container {
    margin: 0 auto;
  }
}
@media (max-width: 1104px) {
  .header-content,
  .width-900-container {
    margin: 0 24px;
  }
}

@media (max-width: 1023px) {
  .gantry-image {
    height: 331px;
    width: 331px;
    margin-top: 20px;
    /* border: 2px solid #ffcd11; */
  }
}

@media screen and (max-width: 600px) {
  .two-columns-column {
    width: 100%;
  }
}
