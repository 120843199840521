/*--- CSS Version 1.2---*/

:root {
  --black-color: #141b29;
  --black-80-color: #444341;
  --white-color: #fff;
  --turquoise-color: #77ddc9;
  --yelow-color: #fcff57;
  --red-color: #ff4d4f;
  --blue-color: #2970ff;
  --grey-color: #f1f4f6;
  --dark-grey-color: #e0e7e7;
  --green-color: #1ad690;
  --light-green-color: #e6faf3;
  --bg-red-color: #ffdada;
  --bg-grey-color: #f9fafb;
  --bg-black-color: #0a0b0c;
}

/* General */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.fade {
  animation: fadeIn 0.3s ease-in-out;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.body-no-scroll {
  overflow: hidden;
  height: 100%;
}

.text-white {
  color: var(--white-color);
}

.text-turquoise {
  color: var(--turquoise-color);
}

.text-yellow {
  color: var(--yelow-color);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-20 {
  left: 20px;
}

.text-black {
  color: #000;
}

.text-black-80 {
  color: var(--black-80-color);
}

.text-green {
  color: var(--green-color);
}

.text-dark-green {
  color: #14c883;
}

.text-red {
  color: var(--red-color);
}

.text-red-90 {
  color: rgba(255, 77, 79, 0.9);
}

.text-blue {
  color: var(--blue-color);
}

.text-grey {
  color: #b0aeae;
}

.text-current {
  color: currentColor;
}

.border-bottom-dashed-grey {
  border-bottom: 1px dashed #e3e3e3;
}

.vh-full {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-45 {
  height: 45px;
}

.min-h-72 {
  min-height: 72px;
}

.min-h-104 {
  min-height: 104px;
}

.h-90 {
  height: 90px;
}

.h-32 {
  height: 32px;
}

.no-underline {
  text-decoration-line: none;
}

.sticky {
  position: sticky;
}

.z-50 {
  z-index: 50;
}

.top-0 {
  top: 0;
}

.top-8 {
  top: 8px;
}

.top-12 {
  top: 12px;
}

.top-20 {
  top: 20px;
}

.bottom-12 {
  bottom: 12px;
}

.left-8 {
  left: 8px;
}

.left-12 {
  left: 12px;
}

.right-8 {
  right: 8px;
}

.right-12 {
  right: 12px;
}

.absolute-middle {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.absolute-bottom {
  left: 52%;
  top: 90%;
  position: absolute;
  transform: translate(-90%, -52%);
}

.absolute-center {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.cursor-zoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
}

.absolute-less-top {
  position: absolute;
  top: -8px;
  right: 56px;
}

.hidden {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-scroll {
  overflow: scroll;
}

.bg-grey {
  background-color: var(--grey-color);
}

.bg-dark-grey {
  background-color: var(--dark-grey-color);
}

.bg-white {
  background-color: var(--white-color);
}

.bg-green {
  background-color: var(--green-color);
}

.bg-light-green {
  background-color: var(--light-green-color);
}

.bg-red {
  background-color: #fff3f3;
}

.bg-blue {
  background-color: #f4f9ff;
}

.bg-black {
  background-color: var(--bg-black-color);
}

.bg-warning {
  background-color: var(--bg-red-color);
  color: var(--red-color);
  border-radius: 6px;
}

.bg-note {
  background-color: #fbfbfb;
  color: var(--black-color);
  border-radius: 6px;
  border: 1px solid #eeeeee;
}

.bg-box {
  background-color: var(--bg-grey-color);
}

.box-1 {
  border: 1px solid #ececec;
  background-color: #fff;
  border-radius: 8px;
}

.box-1-dashed {
  border: 1px dashed #ececec;
  background-color: #fff;
  border-radius: 8px;
}

.box-1-green {
  border: 1px solid #4daa2c;
  background-color: #fff;
  border-radius: 8px;
}

.box-1-red {
  border: 1px solid var(--red-color);
  background-color: #fff;
  border-radius: 8px;
}

.box-1-blue {
  border: 1px solid var(--blue-color);
  background-color: #fff;
  border-radius: 8px;
}

.box-1-current {
  border: 1px solid currentColor;
  background-color: #fff;
  border-radius: 8px;
}

.box-1-yellow {
  border: 1px solid #ffce2f;
  background-color: #fff;
  border-radius: 8px;
}

.box-1-grey {
  border: 1px solid #e9e8eb;
  border-radius: 8px;
  background-color: var(--bg-grey-color);
}

.box-warning {
  background-color: #ffd9d9;
  color: #ff4242;
  font-size: 10px;
  padding: 8px 16px;
}

.border-grey {
  border: 1px solid var(--grey-color);
}

.border-dark-grey {
  border: 1px solid var(--dark-grey-color);
}

.border-b {
  border-bottom: 1px solid #ececec;
}

.border-b-8-white {
  border-bottom: 8px solid var(--white-color);
}

.border-b-current {
  border-bottom: 1px solid currentColor;
}

.border-b-green {
  border-bottom: 1px solid var(--green-color);
}

.border-b-blue {
  border-bottom: 1px solid var(--blue-color);
}

.border-b-red {
  border-bottom: 1px solid var(--red-color);
}

.border-l-grey {
  border-left: 1px solid var(--grey-color);
}

.border-t-dashed {
  border-top: 1px dashed #ececec;
}

.border-t-grey {
  border-top: 1px solid #f5f5f5;
}

.border-t-dark-grey {
  border-top: 1px solid var(--dark-grey-color);
}

.border-r-grey {
  border-right: 1px solid #f5f5f5;
}

.border-y-1 {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.border-white-dashed {
  border: 2px dashed var(--white-color);
}

.border-yellow {
  border: 2px solid var(--yelow-color);
}

.border-red {
  border: 1px solid var(--red-color);
}

.text-xxs {
  font-size: 10px;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-base {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-xl {
  font-size: 20px;
}

.text-2xl {
  font-size: 22px;
}

.text-28 {
  font-size: 28px;
}

.text-32 {
  font-size: 32px;
}

.text-36 {
  font-size: 36px;
}

.text-40 {
  font-size: 40px;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-left {
  text-align: left;
}

.gap {
  gap: 4px;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-0 {
  margin: 0;
}

.mr-8 {
  margin-right: 8px;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 4px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-64 {
  margin-right: 64px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-12 {
  margin-bottom: 12px;
}

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.p-10-9 {
  padding-top: 9px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 7px;
}

.p-8-16 {
  padding: 8px 16px;
}

.p-12-16 {
  padding: 12px 16px;
}

.p-12-24 {
  padding: 12px 24px;
}

.p-4-8 {
  padding: 4px 8px;
}

.p-8 {
  padding: 8px;
}

.p-8-4 {
  padding: 8px 4px;
}

.p-8-0 {
  padding: 8px 0;
}

.p-12-0 {
  padding: 12px 0;
}

.p-24-32 {
  padding: 24px 32px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-12 {
  padding-left: 12px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-64 {
  padding-right: 64px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-32 {
  padding-top: 32px;
}

.p-20 {
  padding: 20px;
}

.p-24 {
  padding: 24px;
}

.p-4 {
  padding: 4px;
}

.p-32 {
  padding: 32px;
}

.p-40 {
  padding: 40px;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-4-6 {
  padding-top: 4px;
  padding-bottom: 6px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.gap-40 {
  gap: 40px;
}

.gap-x-8 {
  column-gap: 8px;
}

.gap-y-32 {
  row-gap: 32px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.leading-normal {
  line-height: normal;
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.middle {
  align-items: center;
}

.end {
  align-items: end;
}

.start {
  align-items: start;
}

.content-center {
  justify-content: center;
}

.content-end {
  justify-content: end;
}

.content-right {
  justify-content: right;
}

.content-space-between {
  justify-content: space-between;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.italic {
  font-style: italic;
}

.font-normal,
.font-normal label {
  font-weight: 400 !important;
}

.page-small {
  padding-left: 70px;
}

.page-large {
  padding-left: 250px;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-672 {
  max-width: 672px !important;
}

.max-w-972 {
  max-width: 972px !important;
}

.w-400 {
  width: 400px;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: calc(100% / 3);
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.hover-rounded-6:hover {
  border-radius: 6px;
}

.rounded-full {
  border-radius: 100%;
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-l-8 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rounded-r-8 {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.btn-blue {
  background-color: var(--blue-color);
  border: 1px solid var(--blue-color);
  color: var(--white-color);
}

.btn-blue:disabled {
  background-color: #94b7ff;
  border: 1px solid #94b7ff;
  color: var(--white-color);
  cursor: not-allowed;
}

.btn-blue:not(:disabled):hover {
  background-color: var(--white-color);
  color: var(--blue-color);
}

.btn-red {
  background-color: var(--red-color);
  border: 1px solid var(--red-color);
}

.btn-green {
  background-color: var(--green-color);
  border: 1px solid var(--green-color);
}

.btn-red:hover {
  background-color: var(--white-color);
  color: var(--red-color);
}

.btn-green:hover {
  background-color: var(--white-color);
  color: var(--green-color);
}

.cust-btn {
  padding: 12px 24px;
  font-weight: 400;
  height: fit-content;
  border-radius: 6px;
  cursor: pointer;
}

.cust-dropdown {
  font-size: 12px;
  font-weight: 700;
  padding: 8px 12px;
  height: inherit;
}

.cust-dropdown:disabled {
  cursor: not-allowed;
  border-color: #d1d5db;
  background-color: #fff;
  color: #9ca3af;
}

.btn-remove {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-chevron {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;

  background-color: var(--white-color);
  box-shadow: 4px 4px 10px #00000029;
}

.btn-chevron.disabled {
  background-color: #e0e1e6;
  color: #7a859b;
  cursor: not-allowed !important;
}

.btn-chevron:hover {
  background-color: var(--bg-black-color);
  color: var(--white-color);
}

.btn-grey-fill {
  font-weight: 700;
  border-radius: 6px;
  background: var(--black-80-color);
  width: 124px;
  height: 40px;
  color: #fff;
  border: 1px solid #444341;
}

.btn-grey-fill:hover {
  background-color: #ffce2f;
  color: #343434;
  cursor: pointer;
}

.btn-grey-border {
  font-weight: 700;
  border-radius: 6px;
  background: #ffce2f;
  width: 124px;
  height: 40px;
  color: #444341;
  border: 1px solid #444341;
}

.btn-grey-border:hover {
  background-color: #444341;
  color: #ffce2f;
  cursor: pointer;
}

.btn-primary {
  background-color: var(--green-color);
  color: var(--white-color);
  border: 1px solid var(--green-color);
}

.btn-primary:not(:disabled):hover {
  background-color: var(--black-color) !important;
  border: 1px solid var(--black-color);
  color: var(--white-color);
}

.btn-primary:disabled {
  background-color: #c1f3e0;
  color: var(--white-color);
  cursor: not-allowed;
  border: #c1f3e0;
}

.btn-secondary {
  background-color: var(--white-color);
  color: var(--black-color);
  border: 1px solid var(--dark-grey-color);
}

.btn-secondary:not(:disabled):hover {
  background-color: var(--black-color) !important;
  border: 1px solid var(--black-color);
  color: var(--white-color);
}

.btn-secondary:disabled {
  background-color: var(--grey-color);
  color: var(--white-color);
  cursor: not-allowed;
  border: var(--grey-color);
}

.btn-filter {
  background-color: var(--black-color);
  border: 1px solid var(--black-color);
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  padding: 0 12px;
  align-items: center;
}

.btn-submit {
  background-color: #ffce2f;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  padding: 12px 20px;
  height: inherit;

  border: 1px solid #ffce2f;
  border-radius: 8px;
  cursor: pointer;
}

.btn-submit:not(:disabled):hover {
  background-color: #343434 !important;
  border: 1px solid #343434;
  color: #fff;
}

.btn-submit:disabled {
  background-color: var(--grey-color);
  color: var(--white-color);
  cursor: not-allowed;
  border: var(--grey-color);
}

.custom-btn-outline {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
}

.btn-action-table {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-action-table svg {
  fill: #000;
}

.btn-secondary svg {
  fill: var(--black-color);
}

.btn-secondary:not(:disabled):hover svg {
  fill: var(--white-color);
}

.btn-outline-yellow {
  background-color: transparent;
  font-size: 12px;
  font-weight: 700;
  color: #ffce2f;
  padding: 12px 20px;
  height: inherit;
  border: 1px solid #ffce2f;
  border-radius: 8px;
  cursor: pointer;
}

.btn-outline-yellow svg {
  fill: #ffce2f;
}

.btn-outline-yellow:hover {
  color: #343434;
  border: 1px solid #343434;
}

.btn-outline-yellow:hover svg {
  fill: #343434;
}

.btn-outline {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  color: #343434;
  cursor: pointer;
  height: 45px;
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  font-weight: 700;
}

.btn-outline:not(:disabled):hover {
  border: 1px solid #343434;
}

.btn-outline:disabled {
  background-color: var(--grey-color);
  color: var(--white-color);
  cursor: not-allowed;
}

.delete-btn {
  color: #343434;
  background-color: #fff;
  border: 1px solid #343434;
}

.delete-btn:not(:disabled):hover {
  color: #fff;
  background-color: #343434;
}

.btn-text-grey {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 12px;
  color: #b0aeae;
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
}

.hover-pointer:hover {
  cursor: pointer;
}

.hover-default:hover {
  cursor: default;
}

.hover-disabled:hover {
  cursor: not-allowed;
}

.hover-grey:hover {
  background-color: var(--bg-grey-color);
}

.hover-dark-grey:hover {
  background-color: #f5f5f5;
}

.btn-text-black {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 12px;
  color: var(--black-color);
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
}

.btn-text-red {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 12px;
  color: var(--red-color);
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
}

.btn-text-blue {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 12px;
  color: var(--blue-color);
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
}

/*  Custom Tabs
------------------------------------------------------------------------------------------ */

#cust-tab .ant-tabs-nav {
  margin: 0;
}

#cust-tab .ant-tabs-nav::before {
  border-bottom: 0;
}

#cust-tab .ant-tabs-tab {
  margin-bottom: 0;
}

#cust-tab .ant-tabs-tab:hover {
  color: var(--black-color);
}

/*  Custom Icon
------------------------------------------------------------------------------------------ */
.icon-xs svg {
  height: 10px;
  width: auto;
}

.icon-sm svg {
  height: 16px;
  width: auto;
}

.icon-base svg {
  height: 24px;
  width: auto;
}

.icon-xl svg {
  height: 40px;
  width: auto;
}

.icon-2xl svg {
  height: 64px;
  width: auto;
}

/*  Custom Form
------------------------------------------------------------------------------------------ */
#cust-form .prefix-location {
  display: none;
}

#cust-form .location-dropdown {
  border: none;
}

#cust-form input,
#cust-form .ant-select,
#cust-form .ant-select-selector {
  align-items: center;
  height: 48px;
  font-size: 14px;
  border-radius: 6px;
}

#cust-form textarea {
  border-radius: 6px;
}

#cust-form textarea:disabled {
  background-color: var(--bg-grey-color);
  color: var(--black-color);
}

#cust-form input,
#cust-form .ant-select-selector {
  padding: 0 16px;
}

.before-icon-pin .ant-select-selection-item::before {
  content: url('../icons/IconPin.svg');
  margin-right: 8px;
  color: var(--black-color);
  vertical-align: middle;
}

.before-icon-location .ant-select-selection-item::before {
  content: url('../icons/IconLocation.svg');
  margin-right: 8px;
  color: var(--black-color);
  vertical-align: middle;
}

.before-icon-calendar .ant-select-selection-item::before {
  content: url('../icons/IconCalendar.svg');
  margin-right: 8px;
  color: var(--black-color);
  vertical-align: middle;
}

.before-icon-scan .ant-select-selection-item::before,
.before-icon-scan .ant-select-selection-placeholder::before {
  content: url('../icons/IconScan.svg');
  margin-right: 8px;
  color: var(--black-color);
  vertical-align: middle;
}

.before-icon-scan .ant-select-selection-placeholder {
  font-style: italic;
}

.loadingDropdown {
  background-color: var(--grey-color);
  height: 48px;
  border-radius: 6px;
}

/*  Comparison Summary Item
------------------------------------------------------------------------------------------ */
.count-loading span {
  width: 14px !important;
  height: 14px !important;
}

.count-loading {
  height: 16px;
  vertical-align: middle;
}

.thumbnail-summary {
  max-width: 180px;
  width: 100%;
}

.comparison-summary-container {
  height: 756px;
  overflow-x: hidden;
  overflow-y: auto;
}

.imageBox {
  width: 150px;
  height: 150px;
  background-color: var(--grey-color);
  box-shadow: -2px 2px 8px #22222262;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageBox .landscape {
  height: 84px;
  width: 150px;
  overflow: hidden;
}

.imageBox .portrait {
  height: 150px;
  width: 84px;
  overflow: hidden;
}

.thumbnail-container img {
  border-radius: 3px;
  height: 100%;
}

.thumbnail-container .btn-image {
  border-radius: 6px;
  height: 65px;
  margin: 12px 8px;
}

.thumbnail-container .active .btn-image,
.thumbnail-container .btn-image:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  cursor: pointer;
}

.thumbnail-container .activeAi {
  position: relative;
}

.thumbnail-container .activeAi::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  cursor: pointer;
  border-radius: 2px;
}

.thumbnail-container .active {
  position: relative;
}
.thumbnail-container .ref-spin .ant-spin-dot-item {
  background-color: var(--yelow-color);
}

.thumbnail-container .active .btn-image::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border: 4px solid var(--yelow-color);
  cursor: pointer;
  border-radius: 2px;
}

.thumbnail-container {
  height: 84px;
  width: 362px;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
}

.zoom-container {
  background-color: var(--grey-color);
  border-radius: 6px;
  height: 400px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.animatedZoomCanvas {
  width: 100%;
  height: 400px;
  border-radius: 12px;
}

.animatedZoomCanvasCropped img,
.animatedZoomCanvasCropped {
  width: auto;
  height: 140px;
  border-radius: 6px;
}

.canvas-image {
  width: 100%;
  height: 400px;
  border-radius: 12px;
  animation: fadeIn 5s;
}

.canvas-preview {
  max-width: 120px;
  max-height: 120px;
  width: auto;
  height: auto;
  border-radius: 6px;
}

.fade-in {
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes popout {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  to {
    transform: scale(1);
  }
}

.popout-animation {
  animation: popout 1s linear;
}

.badge-ai {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
}

.zoom-container .react-transform-wrapper,
.zoom-container .preview-box {
  border-radius: 6px;
}

.cursor-disabled {
  cursor: not-allowed;
}

.transform-preview {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  transform-origin: 0% 0%;
}

#close-up-image.landscape img {
  height: auto;
  width: 100%;
}

#close-up-image.portrait img {
  height: 400px;
  width: auto;
}

/*  Score Board
------------------------------------------------------------------------------------------ */
@font-face {
  font-family: 'Press Start 2P';
  src: url('../fonts/PressStart2P-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.scoreboard-container {
  display: flex;
  justify-content: center;
  font-family: 'Press Start 2P', sans-serif;
  color: var(--white-color);
  padding: 64px;
  align-items: center;
  gap: 80px;
}

.inner-scoreboard {
  width: 800px;
}

.scoreboard-table .title {
  text-align: left;
  color: #f1c385;
  font-size: 28px;
}

.scoreboard-table .items {
  font-size: 18px;
  text-transform: uppercase;
}

.flip {
  transform: scaleX(-1);
}

/*  Loading
------------------------------------------------------------------------------------------ */

.custom-loading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(16, 22, 34, 0.9);
  color: var(--white-color);
}

.loading-content {
  width: 240px;
}

/*  Animate
------------------------------------------------------------------------------------------ */

.animate-bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-h {
  width: 100%;
}

/*  Review
------------------------------------------------------------------------------------------ */

.review-image-container {
  align-items: center;
  width: 100%;
  height: 164px;
  overflow-y: hidden;
  overflow-x: hidden;
  /* min-width: 640px; */
}

.review-image-container img {
  height: 164px;
  width: auto;
  border-radius: 6px;
}

.loading-spin {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(16, 22, 34, 0.8);
  z-index: 99;
}

.review-left-container {
  flex-grow: 1;
  margin-right: 56px;
  padding-right: 70px;
  border-right: 1px solid var(--dark-grey-color);
  overflow: hidden;
}

.review-right-container {
  min-width: 456px;
  width: 456px;
}

.review-caraousel {
  width: 100%;
}

@media (max-width: 1280px) {
  .review-left-container {
    padding-right: 0;
    border-right: 0;
    margin-right: 20px;
  }

  .review-right-container {
    min-width: 400px;
    width: 400px;
  }
}

/*  Custom Modal
------------------------------------------------------------------------------------------ */

.custom-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ant-modal-content {
  border-radius: 6px !important;
}

.modal-content img {
  width: auto;
  height: 60vh;
  border-radius: 6px;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(16, 22, 34, 0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1280px) {
  .lg-w-full {
    width: 100%;
  }

  .lg-flex-column {
    flex-direction: column;
  }

  .lg-flex-row {
    flex-direction: row;
  }

  .lg-gap-32 {
    gap: 32px;
  }

  .lg-w-comparison {
    width: 898px;
  }

  .lg-middle {
    align-items: center;
  }

  .lg-p-32 {
    padding: 32px;
  }

  .lg-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1440px) {
  .zoom-container {
    height: 300px;
  }

  /* .zoom-container .react-transform-wrapper, */
  .zoom-container .preview-box {
    width: 300px;
    height: 300px;
    border-radius: 6px;
  }

  .xl-w-700 {
    width: 700px;
  }
}

.relative-modal-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 500px;
  height: 500px;
  overflow: hidden;
  position: relative;
  background-color: #000;
}

.image-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.3s ease;
}

.btn-chevron.prev,
.btn-chevron.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.btn-chevron.prev {
  right: 100%;
  margin-right: 16px;
}

.btn-chevron.next {
  left: 100%;
  margin-left: 16px;
}

.absolute-bottom {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.btn-chevron.disabled {
  opacity: 0.5;
  pointer-events: none;
}
